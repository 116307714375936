import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  height: 72px;
  background-image: linear-gradient(128deg, #372652 0%, #49A3BC 100%);
`;

export const HeaderContent = styled.div`
  max-width: 1280px;
  height: 72px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: 30px;
`;

export const LogoLink = styled.a`
`;


export const Logo = styled.img`
  width: 180px;
`;
