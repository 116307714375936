import React from 'react';
import { useSpring, animated } from 'react-spring';
import {
  HeaderContainer,
  HeaderContent,
  LogoLink,
  Logo
} from './Style';
import { logo } from '../../assets';

const Header = () => {
  const animationProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 400,
    config: { duration: 1200 },
  });

  const AnimatedLogoLink = animated(LogoLink);

  return (
    <HeaderContainer>
      <HeaderContent>
        <AnimatedLogoLink href="/" style={animationProps}>
          <Logo src={logo} />
        </AnimatedLogoLink>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
