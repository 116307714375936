import { css } from "@emotion/react";

const reset = css`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: '', -apple-system, BlinkMacSystemFont, "Segoe UI", // TODO: Replace beginning empty string with font family we're using
      Roboto, Oxygen-Sans, Ubuntu, Cantarell,
      "Helvetica Neue", sans-serif;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* Box-sizing for app-wide padding/margin inclusive sizing
  *  e.g No Box Sizing     = width 50px; padding-right: 10px = total width 60px;
  *      border-box Sizing = width 50px; padding-right: 10px = total width 50px; 
  */
  * {
    box-sizing: border-box;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
  /* Set to 62.5% font-size (10px) for easier REM calculation, for example 1.6REM = 16px */
  html {
    font-size: 62.5%;
    background: #08040E;
    height: 100%;
    width: 100%;
  }
  body {
    line-height: 1;
    height: 100%;
    font-size: 1.6rem;
    height: 100%;
    width: 100%;
    background: #08040E;
  }
  #root {
    height: 100%;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default reset;
