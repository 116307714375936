import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FooterContainer, FooterPageLinks, FooterLink } from './Style';

const Footer = () => {
  const location = useLocation();
  const [footerSticky, setFooterSticky] = useState(false);

  useEffect(() => {
    if (location.pathname === '/play') {
      setFooterSticky(true);
    }
  }, [location]);

  return (
    <FooterContainer sticky={footerSticky}>
      <FooterPageLinks>
        <FooterLink to="/">Home</FooterLink>
        <FooterLink to="/terms">Terms of Use</FooterLink>
        <FooterLink to="/privacy">Privacy Policy</FooterLink>
      </FooterPageLinks>
    </FooterContainer>
  );
};

export default Footer;
