import styled from '@emotion/styled';

export const BannerContainer = styled.div`
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    height: 270px;
  }

  video {
    height: 100%;
    object-fit: cover;
    background: #08040e;
  }
`;

export const BannerContent = styled.div`
  width: 100%;
  height: 540px;
  z-index: 99;
  position: absolute;
  margin: 0 auto;
  background: none;

  @media only screen and (max-width: 768px) {
    height: 270px;
  }
`;

export const VideoGradient = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.73) 99%
  );
  z-index: 88;
  position: absolute;
  width: 100%;
  height: 540px;
  border-bottom: 1px solid #08040e;

  @media only screen and (max-width: 768px) {
    height: 270px;
  }
`;

export const TagLineContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: 30px;
  height: 540px;

  @media only screen and (max-width: 768px) {
    height: 270px;
  }
`;

export const TagLine = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 38px;
  text-transform: uppercase;
  position: absolute;
  color: white;
  max-width: 540px;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 28px;
  }

  div {
    font-size: 20px;
    line-height: 26px;
    padding-top: 10px;
    font-weight: 700;
  }
`;
