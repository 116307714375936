import React from 'react';
import { useSpring, animated } from 'react-spring';
import {
  BannerContainer,
  BannerContent,
  TagLineContainer,
  TagLine,
  VideoGradient
} from './Style';
import { bannerVideo } from '../../assets';

const Banner = ({ children }) => {

  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1200 },
  });

  const AnimatedBannerContainer = animated(BannerContainer);

  return (
    <AnimatedBannerContainer style={animationProps}>
      <video loop autoPlay muted playsInline>
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <VideoGradient />
      <BannerContent>
        <TagLineContainer>
          <TagLine>
            A new way to tell stories
            <div>We create original stories and technologies to move audiences.</div>
          </TagLine>
        </TagLineContainer>
      </BannerContent>
    </AnimatedBannerContainer>
  );
}

export default Banner;
