import { Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';

import reset from './style/reset';
import fonts from './style/fonts';
import { base } from './style/base';

import { Header, Banner, RouteContainer, Footer } from './components';

function App() {
  return (
    <HelmetProvider>
      <Global styles={[fonts, reset, base]} />
      <Header />
      <Banner />
      <RouteContainer />
      <Footer />
    </HelmetProvider>
  );
}

export default App;
