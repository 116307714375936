import styled from '@emotion/styled';

const ContentContainer = styled.div`
  max-width: 1280px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  padding: 60px 30px;
  background: #f0f2f4;
  border-radius: 20px;

  h1,
  h2 {
    text-transform: uppercase;
  }

  h1 {
    color: #398673;
  }

  h2,
  h3 {
    color: #442b6a;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 30px;
    border-radius: 0;
    margin: 0 auto;
  }
`;

export { ContentContainer };
