import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSpring, animated } from 'react-spring';

import { emailIsValid } from '../../lib/utils';

import {
  LandingFormContainer,
  FormRow,
  LandingContentContainer,
  SignUpContainer,
} from './Style';

const Home = () => {
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [subscribeEmailClass, setSubscribeEmailClass] = useState('');
  const [subscribeFormShow, setSubscribeFormShow] = useState(true);

  //subscribe func
  const subscribe = async () => {
    const data = {
      email: subscribeEmail,
    };

    if (subscribeEmailClass === 'valid') {
      const response = await fetch('/.netlify/functions/subscribe', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log(response);

      setSubscribeEmailClass('');
      setSubscribeFormShow(false);
      localStorage.setItem('subscribed', true);
    }
  };

  //when the subscribe email is changed
  useEffect(() => {
    setSubscribeEmail(localStorage.getItem('subscribeEmail'));
    if (subscribeEmail === '' || subscribeEmail === null) {
      setSubscribeEmailClass('');
    } else {
      if (emailIsValid(subscribeEmail)) {
        setSubscribeEmailClass('valid');
      } else {
        setSubscribeEmailClass('invalid');
      }
    }
  }, [subscribeEmail]);

  //if subscribed
  useEffect(() => {
    if (localStorage.getItem('subscribed')) {
      setSubscribeFormShow(false);
    } else {
      setSubscribeFormShow(true);
    }
  }, []);

  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1200 },
  });

  const AnimatedLandingFormContainer = animated(LandingFormContainer);

  return (
    <>
      <Helmet>
        <title>Monikerr - A new way to tell stories</title>
      </Helmet>
      <AnimatedLandingFormContainer style={animationProps}>
        <LandingContentContainer>
          <div>
            <h2>Projects</h2>
            <p>
              <a href="https://takethekick.fm">Take the Kick</a>
            </p>
            <p>
              <a href="https://kidcast.fm">Kidcast (Beta)</a>
            </p>
          </div>

          <div>
            <h2>About us</h2>
            <p>
              We're a new company based in Melbourne, Australia. We formed to
              create Kidcast.fm and help children become successful learners. In
              the process, we developed some very cool tech with broader
              application so now we're looking at how we can enrich all
              storytelling 🚀.{' '}
            </p>
            <p>
              We're working with storytellers, media, podcasters, game
              developers, app designers and brands to bring audiences into the
              story.
            </p>
            <p>
              Want to make some cool stuff with us? We're open to collaboration
              -<a href="mailto: info@monikerr.com">info@monikerr.com</a>.<br />
              (this goes to both our inboxes so we'll get back to you promptly)
            </p>
          </div>

          <div>
            <h2>Founders</h2>
            <h3>Adam Kershaw</h3>
            <p>
              Children's author, startup advisor and former executive with The
              New York Times.
            </p>
            <h3>Mateusz Michalik</h3>
            <p>Product and dev extraordinaire, makes it look way too easy...</p>
          </div>
        </LandingContentContainer>

        <SignUpContainer>
          <h2>Sign up to receive news and updates</h2>
          {subscribeFormShow ? (
            <>
              <FormRow>
                <label>Your email address</label>
                <input
                  required
                  type="email"
                  id="subscribe-email-input"
                  placeholder="Enter your email here..."
                  value={subscribeEmail || ''}
                  onChange={(event) => {
                    setSubscribeEmail(event.target.value);
                    localStorage.setItem('subscribeEmail', event.target.value);
                  }}
                  className={subscribeEmailClass}
                />
              </FormRow>
              <FormRow end="true">
                <button type="button" onClick={subscribe}>
                  Sign Up
                </button>
              </FormRow>
            </>
          ) : (
            <p>Thanks for reaching out, we'll get in touch soon!</p>
          )}
        </SignUpContainer>
      </AnimatedLandingFormContainer>
    </>
  );
};

export default Home;
