import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Content } from '../../components';

const Privacy = () => (
  <>
    <Helmet>
      <title>Privacy Policy | Kidcast</title>
    </Helmet>
    <Content>
      <h1>Privacy Policy</h1>
      <p>
        Monikerr Pty Ltd (ACN 657 022 369) (<strong>Monikerr</strong>) and our
        related bodies corporate operate a number of websites, mobile apps, and
        media platforms in Australia, including Kidcast and Take The Kick (
        <strong>Platforms</strong>). In conducting our business we may collect,
        use, disclose, and otherwise handle your personal information.
      </p>
      <p>
        We are committed to protecting your personal information and meeting the
        standards set out in the <em>Privacy Act 1988</em> (Cth) (the 
        <strong>Act</strong>), including the Australian Privacy Principles (
        <strong>APPs</strong>). This Privacy Policy sets out how we collect,
        use, disclose and otherwise handle personal information.
      </p>
      <h2>Collection of personal information</h2>
      <h3>What is personal information?</h3>
      <p>
        Personal information means any information or an opinion about an
        identified individual, or an individual who is reasonably identifiable,
        whether that information is true or not and whether it is recorded in a
        material form or not.
      </p>
      <p>
        Sensitive information is a subset of personal information that includes
        information about your race or ethnic origins, political opinions,
        religious or philosophical beliefs, trade union membership, or details
        of health, disability or sexual activity or orientation.  We generally
        do not ask for sensitive information but may do so where it is
        reasonably necessary for our functions and activities and permitted or
        required by law.  Generally, we will only collect sensitive information
        where you have consented to our collecting that information, including
        where you voluntarily submit such information to us.
      </p>
      <h3>What personal Information we collect and hold</h3>
      <p>
        The personal information we collect about you will depend on the nature
        of our relationship with you. We may collect:
      </p>
      <ul>
        <li>
          your name, address, email, phone number(s) and emergency contact
          details;
        </li>
        <li>your age, date of birth, gender;</li>
        <li>payment, credit card and/or bank account details;</li>
        <li>
          photographs, film and/or audio recordings of you where you attend a
          Monikerr affiliated event, attend our premises, call us, or otherwise
          participate on-air; and
        </li>
        <li>
          your interests, opinions about our brands and services, or other
          information relevant to promotions and activities that Monikerr runs
          and to your engagement with Monikerr, such as information contained in
          competition entries, job applications, on-air interactions, complaints
          and enquiries.
        </li>
      </ul>
      <h3>Why we collect personal information</h3>
      <p>
        Generally, we collect your personal information to provide you with
        products or services and to manage our relationship with you.
      </p>
      <p>
        We also collect personal information for research and marketing purposes
        and to provide you with information about events, products and services
        (both our own and those of other companies that may be of interest to
        you), to improve the services we provide, to run various competitions
        and trade promotions, and to provide you with payment receipts.
      </p>
      <p>
        Where lawful and practical, you may deal with us anonymously or use a
        pseudonym.  However, if you do not provide us with your personal
        information, we might not be able to provide you with some or all of our
        products and services (for example, if we require your details in
        connection with a competition we are running, we cannot provide you with
        a prize should you be a prize winner and we cannot contact you).
      </p>
      <h3>How we collect Personal Information</h3>
      <p>
        We collect personal information directly from you when you interact with
        us, for example when:
      </p>
      <ul>
        <li>you submit an entry into a competition or trade promotion;</li>
        <li>you participate in listener loyalty programs;</li>
        <li>you subscribe to a member database or email newsletter;</li>
        <li>
          you send us an email, contact us through any of our websites, or post
          on any of our websites (including any of our social media pages);
        </li>
        <li>you purchase products or services from us;</li>
        <li>you participate in a research survey;</li>
        <li>
          you download one of our mobile Apps or stream our products from a
          website or via a mobile App;
        </li>
        <li>you call us; or</li>
        <li>
          you attend an event or activity that we organise, sponsor, or are
          otherwise affiliated with.
        </li>
      </ul>
      <p>
        We also have CCTV cameras operating in many of our premises to ensure
        security and a good working environment for our staff. Premises where
        CCTV cameras operate have signs at the point of entry, informing you of
        their operation.
      </p>
      <p>
        In some circumstances we may obtain personal information about you from
        third parties such as:
      </p>
      <ul>
        <li>
          marketing databases or other sources that are publicly available such
          as websites, journals, legal directories and phone books;
        </li>
        <li>
          friends or relatives who have provided us with your details as part of
          a promotion, competition or event, including where they have nominated
          you as an emergency contact; and
        </li>
        <li>
          referees or previous employers where you have applied for a job with
          Monikerr.
        </li>
      </ul>
      <h3>Cookies, web beacons and device identifiers</h3>
      <p>
        We use cookies, web beacons and device identifiers to understand how
        users interact with our Platforms, to personalise content, advertise and
        to improve user experience. Cookies are information stored on your
        computer&#x27;s hard disk. Web beacons are tiny graphics that sit on web
        pages. Device identifiers are set by your mobile phone, tablet, desktop
        computer, set-top box, smart TV or other digital hardware.
      </p>
      <p>
        Cookies, web beacons and device identifiers allow us to collect certain
        information including your IP address or other unique identifiers for
        the devices you use to access our Platforms (such as mobile phones,
        tablets, and desktop computers) and to track how your interaction with
        the pages and content on our Platforms.
      </p>
      <p>
        Most web-browsers are set to accept cookies, but you can set your
        web-browser to refuse cookies.
      </p>
      <h3>Technologies</h3>
      <p>
        We have implemented several technologies such as Google Analytics,
        Microsoft Clarity and Mailchimp on our Platforms to collect data related
        to the demographics and interests of visitors to our Platforms.
      </p>
      <p>
        By collecting this data, we can analyse how visitors engage with our
        Platforms, so that we can improve our online offerings for our
        audiences.
      </p>
      <p>
        You can opt out of the Google Analytics’ Demographics and Interest
        Reporting tool by clicking 
        <a href="https://tools.google.com/dlpage/gaoptout/">here.</a>
      </p>
      <h2>Our use and disclosure of personal information</h2>
      <h3>Use of personal information</h3>
      <p>
        How we use your personal information will depend on the nature of our
        relationship with you. We may use your personal information for the
        following purposes:
      </p>
      <ul>
        <li>to provide you with our products or services;</li>
        <li>to manage our relationship with you;</li>
        <li>
          to administer online and App based services and products and to
          monitor your activity on these services and products;
        </li>
        <li>
          to facilitate administration of, and your participation in, a trade
          promotion, competition or other activity;
        </li>
        <li>
          to facilitate administration of, and your participation in, a listener
          loyalty program;
        </li>
        <li>
          to facilitate administration of, and your participation in, a member
          database;
        </li>
        <li>
          to facilitate administration of, and your participation in, an online
          subscription newsletter;
        </li>
        <li>to monitor your purchase history and our relationship with you;</li>
        <li>
          to compile listener databases and profiles to enable us to market
          products and services more accurately;
        </li>
        <li>
          to identify and inform you about other products or services that may
          be of interest to you;
        </li>
        <li>
          to market, improve and add to our products and services including our
          Platforms and our broadcast and digital products and services; and
        </li>
      </ul>
      <h3>Disclosure of personal information</h3>
      <p>We may disclose your personal information to:</p>
      <ul>
        <li>our related bodies corporate;</li>
        <li>
          third parties engaged by us to provide functions on our behalf (such
          as storing and managing databases, processing credit card information,
          mailouts, debt collection, marketing research and advertising), some
          of which may be located overseas;
        </li>
        <li>
          third parties who supply us with content for broadcast or digital
          distribution;
        </li>
        <li>
          regulators and government agencies, such as the Australian
          Communications and Media Authority (<strong>ACMA</strong>);
        </li>
        <li>
          third parties to whom you authorise us to disclose your personal
          information; and
        </li>
        <li>where required or authorised by law.</li>
      </ul>
      <p>
        To protect your privacy, wherever practical we impose contractual
        obligations of security and confidentiality on third parties to whom we
        disclose your personal information.
      </p>
      <h3>Social Media Advertisements</h3>
      <p>
        We may use social media platforms to deliver advertisements to our users
        on these platforms. Your email address is converted to a unique “hashed”
        number that is matched with another unique number to identify you within
        the social media platform.
      </p>
      <h3>Direct marketing opt-out</h3>
      <p>
        If we have collected your Personal Information, we provide opt-out or
        unsubscribe links on all our direct marketing communications including
        email unsubscribe links.  If you no longer wish to receive direct
        marketing or other communications from us entirely, please contact us
        using our details below so that we can update our records.
      </p>
      <h2>Other ways we handle personal information</h2>
      <h3>Data quality</h3>
      <p>
        We take reasonable steps to ensure the personal information we collect,
        use and disclose is accurate, complete, relevant and up-to-date.
      </p>
      <p>
        If you believe the personal information we hold about you is incorrect
        or out-of-date, please contact us on the details below so we can update
        our records.
      </p>
      <h3>Data security</h3>
      <p>
        We take reasonable steps to ensure that the personal information we hold
        about you is protected from misuse, interference and loss and from
        unauthorised access, modification or disclosure.
      </p>
      <p>
        We store your personal information in secure computer storage facilities
        and paper based files, and in other records.  We engage third party
        service providers to assist in storing and processing certain types of
        personal information for us.  Some of these service providers may be
        located overseas.
      </p>
      <h3>Access and correction</h3>
      <p>
        If you wish to request access to, or correction of, any personal
        information we may hold about you, please contact us on the details
        below.
      </p>
      <p>
        Generally, we will provide you with access to your personal information
        unless one of the exceptions set out in the APPs applies to your
        request, such as where providing access would unreasonably impact on the
        privacy of others. If one of these exceptions applies, we may, if
        reasonable, provide access to the personal information in a way that
        meets our needs and yours (for example by giving you access through a
        mutually agreed intermediary), although this will not always be
        possible.
      </p>
      <p>
        We may charge a fee for giving access to personal information which
        reflects our costs of providing access.
      </p>
      <p>
        If we refuse your request for access to, or correction of, your personal
        information, we will provide you with written notification of our
        decision and our reasons for reaching that decision. If we refuse your
        request to correct your personal information, you can request that we
        associate with that information a statement that you believe it to be
        inaccurate, out-of-date, irrelevant or misleading.
      </p>
      <h3>App account and subscription account deletion</h3>
      <p>
        If you close your account or ask us to close your account on any of the
        free or subscription-based services on our Platforms, we will delete or
        anonymise your personal information so it no longer identifies you,
        unless we&#x27;re required to keep or use your personal information for
        a legally justifiable reason. For example, we are required to maintain
        records to comply with legal, tax, audit and accounting obligations, and
        for dispute resolution purposes.
      </p>
      <h2>More information and complaints</h2>
      <p>
        For more information on how we handle your personal information, or if
        you are concerned that we may have breached your privacy and wish to
        make a complaint, please contact us on the details below.
      </p>
      <p>
        We will take any privacy complaint seriously, and we aim to resolve all
        complaints in a timely and efficient manner.  We request that you
        cooperate with us during this process and provide us with relevant
        information we may require.
      </p>
      <p>
        We expect our procedures will deal fairly and promptly with your
        complaint. However, if you are unsatisfied with our response, you can
        make a complaint to the Office of the Australian Information
        Commissioner (<strong>OAIC</strong>) by calling 1300 363 992 or visiting
        the OAIC website at 
        <a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>.
      </p>
      <h2>Contact us</h2>
      <p>
        Email: <a href="mailto: info@monikerr.com">info@monikerr.com</a>
      </p>
      <h2>Changes to our Privacy Policy</h2>
      <p>
        We review this Privacy Policy from time to time to ensure it accurately
        reflects our practices and procedures as well as any changes in the law.
        We will notify you of any changes to this Policy by posting an amended
        version on our website (www.monikerr.com), and changes will take effect
        from the date of posting.
      </p>
    </Content>
  </>
);

export default Privacy;
