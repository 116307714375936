import styled from '@emotion/styled';

export const LandingFormContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 60px 30px;

  h2 {
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #a6dfd3;
    max-width: 750px;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 30px;
    flex-direction: column;
  }
`;

export const LandingContentContainer = styled.div`
  width: 60%;
  padding-right: 40px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  div {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    color: #b0bac9;
  }

  p {
    color: #ccc;
  }

  a {
    color: #b0bac9;
    text-decoration: underline;
  }
`;

export const SignUpContainer = styled.div`
  width: 40%;
  background: #0d0715;
  border-radius: 15px;
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    margin: 0;
  }
`;

export const FormRow = styled.div`
  max-width: 750px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'space-between')};
  padding-top: 30px;

  label {
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
  }

  input {
    background: #f4f4f4;
    border: 1px solid #b0bac9;
    border-radius: 5px;
    height: 50px;
    padding: 0 20px;
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    width: 100%;

    &.valid {
      border: 3px solid #398875;
      color: #398875;
    }

    &.invalid {
      border: 3px solid #9d1f1f;
      color: #9d1f1f;
    }
  }

  button {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    background-image: linear-gradient(180deg, #398673 0%, #41aa95 98%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    height: 55px;
    padding: 0 20px;
    width: 100%;
  }
`;
