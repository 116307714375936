import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Home, Terms, Privacy } from '../../pages';

const RouteContainer = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/privacy" element={<Privacy />} />
  </Routes>
);

export default RouteContainer;
