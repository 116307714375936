import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  width: 100%;
  background: #050308;
  height: 55px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const FooterPageLinks = styled.div`
  display: flex;
`;

export const FooterLink = styled(Link)`
  padding: 0 10px;
  color: #5e6372;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;

  &:hover {
    color: #c5fff2;
  }
`;
